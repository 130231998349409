import React, { useState, useCallback, useRef } from "react";
import { graphql } from "gatsby";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useLocation } from "@reach/router";
import Layout from "../components/layout";
import SEO from "../components/seo";
import References from "../components/references";
import ProjectDetail from "../components/projectDetail";
import get from "lodash-es/get";
import ArrowBackIcon from "../images/icons/arrow_backward.inline.svg";
import ArrowForwardIcon from "../images/icons/arrow_forward.inline.svg";
import Footer from "../components/footer";

import DevelopmentGraphics from "../images/development.inline.svg";
import DigitalizationGraphics from "../images/digitalization.inline.svg";
import OutsourcingGraphics from "../images/outsourcing.inline.svg";

const IndexPage = ({ data }) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const panelScrollRef = useRef(null);
  const [paneOpen, setPaneOpen] = useState(false);
  const [activeCaseStudy, setActivCaseStudy] = useState(1);

  const allReferences = get(data, "allContentfulReference.nodes", []);
  const orderedReferences = get(
    data,
    "allContentfulOrderedReferences.nodes[0].references",
    []
  );
  const references = orderedReferences.length
    ? orderedReferences
    : allReferences;

  const handlePaneOpen = useCallback(() => {
    document.body.classList.add("overflow-hidden");
    if (panelScrollRef.current) panelScrollRef.current.scrollTop = 0;
    setPaneOpen(true);
  }, []);

  const handlePaneClose = useCallback(() => {
    document.body.classList.remove("overflow-hidden");
    setPaneOpen(false);
  }, []);

  const handleOpenCaseStudy1 = useCallback(() => {
    handlePaneOpen();
    setActivCaseStudy(1);
  }, []);
  const handleOpenCaseStudy2 = useCallback(() => {
    handlePaneOpen();
    setActivCaseStudy(2);
  }, []);
  const handleOpenCaseStudy3 = useCallback(() => {
    handlePaneOpen();
    setActivCaseStudy(3);
  }, []);

  return (
    <Layout>
      <SEO title="Younics" />

      <header className="relative pt-40 md:pt-52 pb-32 md:pb-40 md:mb-24">
        <div className="container relative z-10">
          <h1
            className={`text-5xl md:text-6xl lg:text-7xl font-600 ${
              intl.locale === "sk"
                ? "leading-snug md:leading-tight"
                : "leading-tight md:leading-extre-tight"
            } pb-8`}
          >
            {intl.formatMessage({ id: "home.header.title_1" })}
            <br />
            {intl.formatMessage({ id: "home.header.title_2" })}
            <br />
            <span className="text-red-bright">
              {intl.formatMessage({ id: "home.header.title_3" })}
            </span>
          </h1>
          <p
            className={`text-base md:text-lg lg:text-xl pr-2 pb-12 leading-loose text-gray-mid ${
              intl.locale === "sk" ? "max-w-xl" : "max-w-md"
            }`}
          >
            {intl.formatMessage({ id: "home.header.subtitle" })}
          </p>
          <AnchorLink
            to={`${pathname}#contact`}
            className="btn inline-flex items-center group bg-gradient-to-r from-purple to-blue"
          >
            <span className="pr-2">
              {intl.formatMessage({ id: "get_in_touch" })}
            </span>
            <ArrowForwardIcon className="fill-current transition duration-500 ease-in-out transform group-hover:translate-x-2" />
          </AnchorLink>
        </div>
        <div className="absolute left-1/2 ml-40 right-0 top-0 bottom-8 z-0 blend-screen opacity-50 bg-gradient-block-1 rounded-bl-5xl" />
        <div className="absolute left-1/2 mt-16 md:mt-32 top-0 w-104 h-84 z-0 blend-lighten opacity-30 bg-gradient-block-2 animate-float-2-border-br animation-border-bl " />
        <div className="absolute left-1/2 ml-20 mt-32 md:mt-52 top-0 w-108 h-96 z-0 blend-lighten opacity-30 bg-gradient-block-2 animate-float-1-border-tl animation-border-tl rounded-br-4xl" />
      </header>

      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 pb-24 md:pb-32 lg:pb-40 xl:pb-48">
          <div className="flex justify-center lg:pr-16 pb-6 lg:pb-0 relative -mb-20/9 md:-mb-12 lg:mb-0">
            <DevelopmentGraphics className="w-full h-auto max-w-md md:max-w-none md:w-64 md:h-64 lg:w-auto lg:h-auto" />
            <div className="xl:hidden absolute bottom-0 h-3/4 left-0 right-0 bg-gradient-to-t from-black via-black-a80 md:via-black-a50 lg:via-black-a25 to-transparent" />
          </div>
          <div className="flex items-center lg:pl-16 relative z-10">
            <div>
              <h2 className="inline-block font-600 tracking-widest uppercase bg-clip-text text-transparent bg-gradient-to-r from-purple to-blue">
                {intl.formatMessage({ id: "home.project_types.type_1.title" })}
              </h2>
              <h3 className="text-3xl xl:text-5xxl leading-tight max-w-lg font-500 py-6 lg:py-8">
                {intl.formatMessage({
                  id: "home.project_types.type_1.sub_title",
                })}
              </h3>
              <p className="text-gray-purple leading-loose lg:max-w-lg pb-9">
                {intl.formatMessage({ id: "home.project_types.type_1.text" })}
              </p>
              <button
                onClick={handleOpenCaseStudy1}
                className="btn bg-white-a10 hover:bg-pink transition-colors duration-1000 inline-flex items-center group focus:outline-none"
                aria-label="Open Case Study"
              >
                <span className="pr-2">Case Study</span>
                <ArrowForwardIcon className="fill-current transition duration-700 ease-in-out transform group-hover:translate-x-2" />
              </button>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 pb-24 md:pb-32 lg:pb-40 xl:pb-48">
          <div className="order-1 lg:order-2 flex justify-center lg:pl-16 pb-6 lg:pb-0 relative -mb-20/9 md:-mb-12 lg:mb-0">
            <DigitalizationGraphics className="w-full h-auto max-w-md md:max-w-none md:w-64 md:h-64 lg:w-auto lg:h-auto" />
            <div className="xl:hidden absolute bottom-0 h-3/4 left-0 right-0 bg-gradient-to-t from-black via-black-a80 md:via-black-a50 lg:via-black-a25 to-transparent" />
          </div>
          <div className="order-2 lg:order-1 flex items-center lg:pr-16 relative z-10">
            <div>
              <h2 className="inline-block font-600 tracking-widest uppercase bg-clip-text text-transparent bg-gradient-to-r from-purple to-blue">
                {intl.formatMessage({ id: "home.project_types.type_2.title" })}
              </h2>
              <h3 className="text-3xl xl:text-5xxl leading-tight max-w-lg font-500 py-6 lg:py-8">
                {intl.formatMessage({
                  id: "home.project_types.type_2.sub_title",
                })}
              </h3>
              <p className="text-gray-purple leading-loose lg:max-w-lg pb-9">
                {intl.formatMessage({ id: "home.project_types.type_2.text" })}
              </p>
              <button
                onClick={handleOpenCaseStudy2}
                className="btn bg-white-a10 hover:bg-purple transition-colors duration-1000 inline-flex items-center group focus:outline-none"
                aria-label="Open Case Study"
              >
                <span className="pr-2">Case Study</span>
                <ArrowForwardIcon className="fill-current transition duration-700 ease-in-out transform group-hover:translate-x-2" />
              </button>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 pb-24 md:pb-32 lg:pb-40 xl:pb-48">
          <div className="flex justify-center lg:pr-16 pb-6 lg:pb-0 relative -mb-20/9 md:-mb-12 lg:mb-0">
            <OutsourcingGraphics className="w-full h-auto max-w-md md:max-w-none md:w-64 md:h-64 lg:w-auto lg:h-auto" />
            <div className="xl:hidden absolute bottom-0 h-3/4 left-0 right-0 bg-gradient-to-t from-black via-black-a80 md:via-black-a50 lg:via-black-a25 to-transparent" />
          </div>
          <div className="flex items-center lg:pl-16 relative z-10">
            <div>
              <h2 className="inline-block font-600 tracking-widest uppercase bg-clip-text text-transparent bg-gradient-to-r from-purple to-blue">
                {intl.formatMessage({ id: "home.project_types.type_3.title" })}
              </h2>
              <h3 className="text-3xl xl:text-5xxl leading-tight max-w-lg font-500 py-6 lg:py-8">
                {intl.formatMessage({
                  id: "home.project_types.type_3.sub_title",
                })}
              </h3>
              <p className="text-gray-purple leading-loose lg:max-w-lg pb-9">
                {intl.formatMessage({ id: "home.project_types.type_3.text" })}
              </p>
              <button
                onClick={handleOpenCaseStudy3}
                className="btn bg-white-a10 hover:bg-blue transition-colors duration-1000 inline-flex items-center group focus:outline-none"
                aria-label="Open Case Study"
              >
                <span className="pr-2">Case Study</span>
                <ArrowForwardIcon className="fill-current transition duration-700 ease-in-out transform group-hover:translate-x-2" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <References references={references} />
      </div>

      <div className="relative z-10 mb-8 mt-24 lg:mt-32 xl:mt-40">
        <div className="hidden md:block absolute z-0 top-0 h-full left-4 right-4 md:left-1/2 md:right-0 md:rounded-tl-5xl bg-gradient-to-b from-purple-dark to-blue-dark-a0 opacity-60 blend-lighten" />
        <div className="hidden md:block absolute z-0 top-0 h-full left-4 right-4 md:right-1/2 md:left-0 md:rounded-br-5xl bg-gradient-to-t from-purple-dark to-blue-dark-a0 opacity-60 blend-lighten" />
        <div className="relative z-10 container flex flex-wrap">
          <div className="w-full md:w-1/2 md:pr-20 px-4 md:pl-0 py-6 md:py-24 lg:py-28 xl:py-40 flex justify-start bg-purple-dark bg-opacity-60 md:bg-transparent mb-4 md:mb-0">
            <div>
              <h2 className="inline-block font-600 tracking-widest uppercase bg-clip-text text-transparent bg-gradient-to-r from-purple to-blue pb-8">
                {intl.formatMessage({ id: "what_you_get.value.value" })}
              </h2>
              <h3 className="text-2xl md:text-3xl xl:text-5xxl leading-tight font-500 pb-8 xl:max-w-lg">
                {intl.formatMessage({ id: "what_you_get.value.title" })}
              </h3>
              <p className="text-gray-purple leading-loose lg:max-w-lg xl:pr-12">
                {intl.formatMessage({ id: "what_you_get.value.text" })}
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-4 md:px-0 md:pl-20 py-6 md:py-24 lg:py-28 xl:py-40 flex justify-end bg-purple-dark bg-opacity-60 md:bg-transparent">
            <div>
              <h2 className="inline-block font-600 tracking-widest uppercase bg-clip-text text-transparent bg-gradient-to-r from-purple to-blue pb-8">
                {intl.formatMessage({ id: "what_you_get.growth.growth" })}
              </h2>
              <h3 className="text-2xl md:text-3xl xl:text-5xxl leading-tight font-500 pb-8 xl:max-w-lg xl:pr-20">
                {intl.formatMessage({ id: "what_you_get.growth.title" })}
              </h3>
              <p className="text-gray-purple leading-loose lg:max-w-lg xl:pr-12">
                {intl.formatMessage({ id: "what_you_get.growth.text" })}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${
          paneOpen
            ? "opacity-100 bg-black-a80 z-10 fixed"
            : "opacity-0 bg-transparent -z-10 absolute"
        }  hidden md:block top-0 bottom-0 right-0 left-0 transition-opacity duration-700`}
        onClick={handlePaneClose}
      />

      <div
        className={`${
          paneOpen ? "left-0" : "-left-full"
        } fixed flex items-center lg:justify-center pl-4 lg:pl-0 cursor-pointer top-0 lg:h-full w-full lg:w-1/12 bg-purple-light opacity-90 hover:opacity-100 group transition-all duration-1000 py-4 focus:outline-none z-40 group`}
        onClick={handlePaneClose}
      >
        <ArrowBackIcon className="fill-current transform transition-transform duration-700 group-hover:-translate-x-2 flex-shrink-0" />
      </div>

      <div
        className={`${
          paneOpen ? "right-0" : "-right-full -mr-32"
        } fixed top-0 bottom-0 w-full lg:w-11/12 z-30 bg-black shadow-pane-blue transition-all duration-700  `}
      >
        <div
          className="w-full h-full overflow-y-auto overflow-x-hidden"
          ref={panelScrollRef}
        >
          <ProjectDetail
            project={
              activeCaseStudy === 1
                ? data.caseStudy1
                : activeCaseStudy === 2
                ? data.caseStudy2
                : activeCaseStudy === 3
                ? data.caseStudy3
                : null
            }
          />
          <div className="pt-16 md:pt-24 lg:pt-32 flex justify-center">
            <Link
              to="/projects"
              onClick={handlePaneClose}
              className="btn w-full h-32 lg:h-40 bg-gradient-to-r from-purple-l1 to-purple-l2 bg-opacity-60 hover:bg-opacity-100 transition-colors duration-1000 flex items-center group focus:outline-none"
            >
              <div className="container flex items-center font-500 text-xl lg:text-3xl">
                <span className="pr-3 lg:pr-4">
                  <FormattedMessage
                    id="projects.show_more_projects"
                    defaultMessage="See all Projects"
                  />
                </span>
                <ArrowForwardIcon className="fill-current transition duration-700 ease-in-out transform group-hover:translate-x-2 lg:w-8 lg:h-8" />
              </div>
            </Link>
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  fragment Reference on ContentfulReference {
    id
    quote {
      quote
    }
    author
    color
    logo {
      title
      description
      file {
        url
        details {
          image {
            width
            height
          }
        }
      }
    }
    avatar {
      title
      description
      localFile {
        childImageSharp {
          fixed(width: 36, height: 36) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  }
  query IndexQuery($locale: String) {
    allContentfulOrderedReferences(filter: { node_locale: { eq: $locale } }) {
      nodes {
        references {
          ...Reference
        }
      }
    }
    allContentfulReference(filter: { node_locale: { eq: $locale } }) {
      nodes {
        ...Reference
      }
    }
    caseStudy1: contentfulProject(
      node_locale: { eq: $locale }
      slug: { eq: "web-portal-for-sponsor-online" }
    ) {
      ...ProjectDetail
    }
    caseStudy2: contentfulProject(
      node_locale: { eq: $locale }
      slug: { eq: "web-app-for-shmu" }
    ) {
      ...ProjectDetail
    }
    caseStudy3: contentfulProject(
      node_locale: { eq: $locale }
      slug: { eq: "website-for-healthmode" }
    ) {
      ...ProjectDetail
    }
  }
`;
